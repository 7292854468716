import ContactUs from '@mindful-web/marko-web-contact-us/browser';
import MonoRail from '@mindful-web/marko-web-theme-monorail/browser';
import Leaders from '@mindful-web/marko-web-leaders/browser';

const FixedAdBottomHandler = () => import(/* webpackChunkName: "fixed-ad-bottom-handler" */ './fixed-ad-bottom-handler.vue');
const ParallaxAdHandler = () => import(/* webpackChunkName: "parallax-ad-handler" */ './parallax-ad-handler.vue');
const PrestitialAdHandler = () => import(/* webpackChunkName: "prestitial-ad-handler" */ './prestitial-ad-handler.vue');

export default (Browser) => {
  Browser.register('FixedAdBottomHandler', FixedAdBottomHandler);
  Browser.register('PrestitialAdHandler', PrestitialAdHandler);
  Browser.register('ParallaxAdHandler', ParallaxAdHandler);

  ContactUs(Browser);
  MonoRail(Browser);
  Leaders(Browser);
};
